<template>
  <div>
    <b-table
      :data="integrations"
      :selected.sync="selected"
      hoverable
      focusable
      :loading="loading"
      class="table-row"
    >
      <b-table-column field="name" label="Name " v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="clientId" label="Client Id" v-slot="props">
         <span class="tag">
          {{ props.row.clientId }}
        </span>
      </b-table-column>
    </b-table>
    <div class="columns actions">
      <b-field class="action">
          <b-button
              type="button"
              label="Create New Integrations"
              icon-left="mdi mdi-key-plus"
              id="create-integration-button"
              @click="createIntegration"
              class="button is-success"
          >
          </b-button>
      </b-field>
      <b-field class="action">
        <b-button
          label="Delete Selected Integration"
          type="is-danger"
          icon-left="close"
          :disabled="!selected"
          @click="deleteIntegration" />
      </b-field>
    </div>
  </div>
</template>
<script>

export default {
  name: 'IntegrationsList',
  data() {
    return {
      integrations: [],
      loading: true,
      dataService: null,
      selected: null,
    };
  },
  created() {
    this.dataService = this.$dataService(this.$route.params.tenant);
    this.retrieveIntegrations();
  },
  methods: {
    onError(msg) {
      this.$buefy.dialog.alert({
        title: 'Error',
        message: msg,
        type: 'is-danger',
        hasIcon: true,
        icon: 'alert-circle-outline',
        iconPack: 'mdi',
      });
    },
    deleteIntegration() {
      this.$buefy.dialog.prompt({
        title: 'Delete Integration',
        message: 'Type the integration name in order to delete the selected integration. Beware that this process <b>cannot</b> be undone',
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        inputAttrs: {
          type: 'text',
          placeholder: this.selected.name,
        },
        onConfirm: async (content) => {
          if (content === this.selected.name) {
            try {
              await this.dataService.integrations.delete(this.selected.clientId);
              this.$buefy.toast.open(`Integration deleted! ${content}`);
              await this.retrieveIntegrations();
              this.selected = null;
            } catch (err) {
              this.onError('There was a problem deleting the integration, try refreshing the page do it again.');
            }
          } else {
            this.onError('Integration name doesn\'t match. It will not be deleted!');
          }
        },
      });
    },
    createIntegration() {
      this.$router.push({ name: 'createIntegration' });
    },
    async retrieveIntegrations() {
      this.loading = true;
      this.integrations = [];
      const { data } = await this.dataService.integrations.list();

      this.integrations = data;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.actions{
  margin: 0.5em
}
.action{
  margin: 0.5em
}
</style>

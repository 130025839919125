<template>
  <div class="main-view">
    <Integration-list :tenant-code="tenant" />
  </div>
</template>

<script>
import IntegrationList from '@/components/integrations/IntegrationList.vue';

export default {
  name: 'IntegrationListView',
  components: {
    IntegrationList,
  },
  computed: {
    tenant() {
      return this.$route.params.tenant;
    },
  },
};
</script>
